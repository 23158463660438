<template>
	<div class="page-cases">
		<div class="detail">
			<div class="custom-headline no-line">开业45天，日销连续稳居前三，高于平均销售额120%的商户是如何经营的？</div>
			<div class="tag">国美家营销</div>
			<div class="img-box">
				<el-image :src="imgList[0]" lazy></el-image>
			</div>
			<div class="img-box">
				<el-image :src="imgList[1]" lazy></el-image>
			</div>
			<div class="text">香鼎坊餐饮品牌起源于2007年，是一家以传统湖南、江西风味为主，加入时尚、健康新元素，具有独特饮食文化的连锁餐饮品牌。香鼎坊以特色经营为基础，以服务满意为保证，着力打造名为“家乡小炒，滋味烧菜”湘赣菜新理念。菜肴丰满朴实、注重原味，口感肥厚，讲究火功。菜品制法各异，工艺讲究，颇有特色。自国美家美食美客商务食堂出现在大众视野。“香鼎坊”作为入驻品牌之一，在过去45天的经营过程中业绩遥遥领先，在销售额和订单量上长期居于前列，交出了亮眼的答卷。那么香鼎坊具体是怎么做的？今天我们一起拆解香鼎坊日销稳居前三的打法，分享商户经营的可复用方法。</div>
			<div class="img-box">
				<el-image :src="imgList[2]" lazy></el-image>
			</div>
			<div class="img-box">
				<el-image :src="imgList[3]" lazy></el-image>
			</div>
			<div class="img-box">
				<el-image :src="imgList[4]" lazy></el-image>
			</div>
			<div class="text">对于美食美客商务食堂“香鼎坊”品牌的经营关注点，产品运营经理张坤伦提到：“香鼎坊原来是做酒楼生意，最早在北京有7家店，这是我们第一次入驻商务食堂。在菜品品质和厨师的选择上，我们将原来酒楼的品质搬进食堂， 并将原来酒楼的厨师也请了过来，虽然人工成本要高很多，但吃饭是大事儿，我们希望在这栋楼里的同事能好好吃饭，吃好饭！所以我们在食材、配料、油水等方面都经过层层精选，严格把关，我们认为好的材料、好的菜品、好的技术是一切的保障。”因为坚持这样的理念，香鼎坊始终保持着酒楼菜肴的独有风味，讲究菜品原汁原味、油厚不腻、口味浓厚、咸鲜兼辣。注重菜肴的质地，制作精细，并保有浓郁的乡土风味。</div>
			<div class="img-box">
				<el-image :src="imgList[5]" lazy></el-image>
			</div>
			<div class="text">香鼎坊一直做酒楼及餐厅生意，对于菜品品质极为注重。在经营初期，香鼎坊和其他商户一样，也面临许多问题。首当其冲的就是定价问题，因为食材成本高，在菜品上线的初期，香鼎坊收到来自顾客反馈，提到价格贵。与此同时，平台运营人员通过多渠道的反馈信息，也给出一些调整价格的建议。价格降低，意味着要控制成本，食材的品质就要做出妥协。最简单的方式，就是降低食材成本，辅料也可以换成不那么知名的品牌。但如果是这样，就与香鼎坊本身的品牌香鼎坊的菜系一直都以荤菜为主，一次偶然的机会，有顾客提到：“如果你们的菜里面，可以有一些配菜就好了，这样更加健康，营养均衡。”顾客的反馈给香鼎坊的调整策略带来了新的灵感。如果可以推出荤素搭配的小碗菜系列，增加素菜，这样就能有效控制成本，降低定价，让顾问以更加亲民的价格，体验到优质口感，同时营养健康。 在经过了几天的调整尝试，收到顾客不错的回馈，这更加坚定了他们当初的理念：坚持品质为王。</div>
			<div class="img-box">
				<el-image :src="imgList[6]" lazy></el-image>
			</div>
			<div class="img-box">
				<el-image :src="imgList[7]" lazy></el-image>
			</div>
			<div class="text">香鼎坊自开业以来，每天保持30%-40%的菜品更新率。午餐和晚餐也至少也调整20%的不同菜品，让顾客始终保持新鲜感和期待感，体验丰富的菜品口味。通常来讲，即便某一个明星菜品卖得非常好，在最多连续推出两天后，就会进行更换。让顾客对喜爱的菜肴留有念想和期待，就会吸引其不断复购，而不是很快吃腻。最后，我们期待在国美家的平台上，每一位入驻商户，都能最大化的发挥自身优势。在追求不同经营阶段目标实现的过程中，始终坚持优质菜品为核心，服务保证为基准的经营理念。在众多经验和案例的交流分享中，一起成长，相融共生。</div>
		</div>
		<!-- 放在外层与浏览器同宽 -->
		<div class="more-cases">
			<div class="title">更多精彩案例</div>
			<div class="cases-item">
				<div class="case-content-con" v-for="item in caseList" :key="item.id" @click="jump2casesDetail(item.id)">
					<el-image class="case-img" :src="item.image" fit="fill">
					</el-image>
					<div class="case-content-box">
						<div class="case-content-title">
							{{ item.title }}
						</div>
						<div class="case-content-des">{{ item.des }}</div>
						<div class="case-content-time">{{ item.date }}</div>
						<div class="case-content-sign">阅读全部 ></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			imgList: [
				require("../assets/img/cases/demo1/1.png"),
				require("../assets/img/cases/demo1/2.png"),
				require("../assets/img/cases/demo1/3.png"),
				require("../assets/img/cases/demo1/4.png"),
				require("../assets/img/cases/demo1/5.png"),
				require("../assets/img/cases/demo1/6.png"),
				require("../assets/img/cases/demo1/7.png"),
				require("../assets/img/cases/demo1/8.png"),
			],
			caseList: [
				{
					id: 2,
					image: require("../assets/img/cases/demo2/2.png"),
					title: "高出平均销售额220%+，揭秘快充时刻如何成为“食堂爆款”！",
					des: "2021年10月，快充时刻在美食美客食堂营业仅30 天，就以销售额高于平均水平220%的优势展现出了惊人的爆发力，成为美食美客最受欢迎的快餐品牌之一。",
					date: "2021.10.15",
				},
				{
					id: 3,
					image: require("../assets/img/cases/demo3/1.jpg"),
					title: "商户版-国美家商户扶持活动-美丽雅",
					des: "美丽雅女王节活动”是国美家与成都家居清洁用品龙头企业美丽雅，基于3月8日妇女节打造的“女王节”推广活动。本次活动主要通过纯线上的社群运营在全国范围内推广，是美丽雅＆国美家首次合作，也是国美家商户扶持政策的重要组成部分。",
					date: "2022.03.08",
				},
			],
		};
	},
	methods: {
		jump2casesDetail(id) {
      this.$router.push({
        name: `CasesDetail${id}`,
      })
    },
	},
};
</script>


<style lang="scss" scoped>
@import "../assets/styles/base.scss";
.el-img {
	width: 100%;
	margin: 0 auto;
}
.page-cases {
	position: relative;
	width: 1200px;
	margin: auto;
	text-align: left;
	margin-top: 20px;
	.detail {
		font-size: 14px;
		.no-line {
			font-size: 26px;
			color: #1f2329;
			margin-bottom: 17px;
			&::after {
				background: none;
			}
		}
		.tag {
			font-size: 12px;
			color: #f08107;
			width: 90px;
			height: 32px;
			line-height: 32px;
			background-color: #feead2;
			text-align: center;
			margin-bottom: 17px;
		}
		.img-box {
			margin: 26px 0;
		}
		.text {
			text-indent: 2em;
		}
	}
}
.more-cases {
	.title {
		position: relative;
		font-size: 22px;
		font-weight: 500;
		color: #1f2329;
		text-align: center;
		margin-top: 90px;
		margin-bottom: 50px;
		&::after {
			content: "";
			position: absolute;
			bottom: -5px;
			left: 0;
			right: 0;
			width: 50px;
			height: 5px;
			margin: 0 auto;
			border-radius: 5px;
			background: linear-gradient(to right, #f6ba4f, #ea8427);
		}
	}
}
.cases-item {
	display: flex;
	.case-content-con {
		width: 386px;
		height: 523px;
		background: #fafafa;
		border-radius: 10px;
		margin-right: 60px;
		background-color: #fff;
		cursor: pointer;
		transition: box-shadow 0.3s linear;
		&:hover {
			cursor: pointer;
			box-shadow: 0px 4px 4px 0px #e9edf1;
		}
		.case-img {
			width: 385px;
			height: 201px;
			border-radius: 10px;
		}
		.case-content-box {
			width: 330px;
			padding: 40px 28px 26px 28px;
		}
		.case-content-title {
			width: 100%;
			height: 30px;
			font-size: 20px;
			font-family: HiraginoSansGB-W6, HiraginoSansGB;
			font-weight: normal;
			color: #1f2329;
			line-height: 30px;
			overflow: hidden;
			white-space: nowrap; /*把文本强制显示在一行*/
			text-overflow: ellipsis; /*超出部分显示...*/
		}
		.case-content-des {
			margin-top: 16px;
			width: 100%;
			// height: 74px;
			font-size: 14px;
			// font-family: MicrosoftYaHei;
			color: #666666;
			line-height: 24px;
			overflow: hidden;
			text-overflow: ellipsis; /*超出部分显示...*/
			display: -webkit-box;
			line-clamp: 3;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
		}
		.case-content-time {
			margin-top: 16px;
			height: 19px;
			font-size: 14px;
			// font-family: MicrosoftYaHei;
			color: #666666;
			line-height: 19px;
		}
		.case-content-sign {
			width: 91px;
			height: 24px;
			font-size: 18px;
			// font-family: MicrosoftYaHei;
			color: #eb8729;
			line-height: 24px;
			margin-top: 66px;
			cursor: pointer;
		}
	}
}
</style>